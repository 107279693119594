@import '~antd/dist/antd.less';
@import url('font.css');
//@import 'highcharts/css/highcharts.css';


:root {
  --primary-color: #1890FF;
  --reported-color: #E5E5E5;
  --assigned-color: #096DD9;
  --working-color: #FA8C16;
  --quoted-color: #722ED1;
  --declined-color: #F5222D;
  --bound-color: #52C41A;
  --ntu-color: #000000D9;
  --draft-color: #69C0FF;
  --issued-color: #135200;
  --renewing-color: #FFEC3D;
  --tbr-color: #3D50FF;
  --referred-color: #13C2C2;
  --referral-approved-color: #FA8C16;
  --referral-declined-color: #F5222D;
  --robot-reader-color: #BFBFBF;
  --reversed-color: #002766;
  --expired-color: #A70E41;

  --cyber-color: #cc5522;
  --do-color: #ff4400;
  --bbb-color: #eecc55;
  --pi-color: #ffee00;
  --wi-color: #bbaabb;
  --legal-protection--color: #558888;
  --property-color: #4488aa;
  --car-color: #003f5c;
  --ear-color: #f1d4d4;
  --decennial-insurance-color: #de425b;
  --leasing-color: #488f31;
  --fine-arts-color: #a8c163;
  --injuries-color: #52C41A;
  --liability-color: #f38359;
  --rc-green-color: #722ED1;
}

*, body, html, h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI";
  box-sizing: 'border-box';
  scroll-behavior: smooth;

}

h1, h2, h3, h4, h5, h6, p, div.ant-typography {
  margin: 0 !important;
  padding: 0 !important;

}

.btn-text {
  cursor: pointer;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
  transition: ease 1s all;
}

.btn-text:hover {
  color: var(--primary-color);
}

/* Antd flex row has default margins, setting it to 0 with styles in component cause render error*/
.ant-row-flex, .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.ant-collapse-header-text {
  width: 100%
}

.ant-collapse-header {
  padding: 0 !important;
  align-items: center !important;
}

.ant-collapse-content-box {
  padding: 0 !important;
}

//.ant-picker-month-panel .ant-picker-header {
//  display: none;
//}

#riskapp-logo {
  color: white
}

.negotiationId-sidebar-backbutton {
  cursor: pointer;
}

.negotiation-form__container {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.negotiationId-sidebar-backbutton h5 {
  /* display: inline-block; */
  padding-bottom: 2px;
  background-image: linear-gradient(#000, #000);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s,
  background-position 0s 0.3s; /*change after the size immediately*/
}

.ant-menu-item-divider {
  visibility: hidden;
}

.negotiationId-sidebar-backbutton:hover h5 {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% 2px;;
}

// statistics
.statistic-card {
  border-radius: 12px;
  padding: 1.5rem;
}

.negotiation-overview--charts, .hit-ratio-container {
  margin-top: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  flex-grow: 1;
}

.authorities--charts {
  flex-grow: 1;
  display: inline-flex;
  gap: 1rem;
}

.negotiations-to-authorize--chart {
  width: 65%;
}

.negotiations-to-authorize--chart .ant-table{
  min-height: 25rem;
  background-color: white !important;
}

.gap-to-plan-negotiations--chart {
  width: 35%;
}

@media screen and (max-width: 1400px) {
  .authorities--charts {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    flex: 1;
  }

  .negotiations-to-authorize--chart, .gap-to-plan-negotiations--chart {
    width: 100%;
  }

  .negotiations-to-authorize--chart .ant-table{
    min-height: auto;
  }
}

.negotiation-overview--charts .ant-card-bordered {
  flex-basis: 32%;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: 16rem;
}

.hit-ratio-container .ant-card-bordered {
  flex-basis: 49%;
  flex-grow: 1;
  flex-wrap: wrap;
}

.statistic-card div.ant-statistic-title {
  font-weight: bold;
  font-size: 20px;
  color: black;
}

.statistic-card span.ant-statistic-content-value {
  font-weight: bold;
  font-size: 24px;
  color: black;
}

.statistic-card dd {
  font-weight: bold;
}

.statistic-card__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: normal;
  min-width: 250px;
}

.statistic-card__list div {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statistic-card__list dt {
  font-weight: normal;
  width: fit-content;
  margin-right: 0.5em;
}


.policies-statistics__list div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem
}

.policies-statistics__list div::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #69b6d5;
}

// ***

// header
.header {
  line-height: normal;
}

@layout-header-height: 3.25rem;

@menu-dark-item-active-bg: none;
@menu-dark-selected-item-icon-color: @primary-color;
@menu-dark-selected-item-text-color: @primary-color;

.header .ant-menu {
  height: 100%;
}

.header .ant-menu-item-divider {
  flex-grow: 1;
  order: 4;
}

.header .ant-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.header .ant-menu-title-content {
  margin-left: 0 !important;
}

.header .ant-menu li:nth-child(1) {
  flex-direction: row;
  align-items: center;
}

.header .ant-menu li:nth-child(1) .ant-menu-title-content {
  padding-left: 0.75rem
}

// ***

// body
.ant-layout-content {
  background-color: #f0f2f5;
  min-height: calc(100vh - @layout-header-height);
}

//**

// radio options for changing the view
.views-radio .ant-radio-wrapper {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: row-reverse !important;
}

.views-radio .ant-radio-wrapper span:last-child {
  flex-grow: 1;
}

.views-radio p {
  width: 70%;
}

// ***

// MODAL
@modal-footer-padding-vertical: 2rem;
@modal-footer-padding-horizontal: 2rem 1.5rem;
// ***


.login-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 95vh;
}

.gap-to-plan-form .ant-form-item-control-input {
  text-align: end;
}

.negotiation-to-assign-cards > .ant-card-body > .ant-row {
  gap: 1rem;
}

// UPLOAD
.upload-doc .ant-upload {
  width: 100%
}

// TABLES
// todo set the colour of the active / selected table item
@table-header-bg: #FAFAFA !important;
@table-bg: #FAFAFA !important;

// reset table header to default alignment after setting number column alignment to right (last child because the columns could have children)
.ant-table-thead tr:last-child th {
  text-align: left !important;
}

.negotiation-table .ant-table-row {
  cursor: pointer;
}

.ant-table-tbody .table__negotiation-expired-row > td {
  background-color: #ffc7c78c !important;
}

.ant-table-tbody > tr > td {
  background-color: #fff;
}

.broker-data--table tr.ant-table-expanded-row td {
  background-color: #e6f7ff;
}

.broker-data--table tr.ant-table-row-selected-header td {
  background-color: #c1ebff;
}

.broker-data--table tr:hover.ant-table-row-selected-header td {
  background-color: #9cd5fc;
}

// ant tree
.ant-tree-node-content-wrapper {
  display: flex;
  align-items: center;
}

.ant-tree-title {
  flex-grow: 1;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


// DASHBOARD - LAYOUT

/*.dashboard-data-header {
  //  grid-area: dashboard-data-header;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #eff2f5;
  padding: 16px;
  width: 100%;
}*/


.status-negotiations-header {
  grid-area: status-negotiations-header;
}

.hit-ratio-header {
  grid-area: hit-ratio-header;
}

.monthly-data-header {
  grid-area: monthly-data-header;
}

.negotiations-status--chart {
  grid-area: negotiations-status;
}

.negotiations-premiums--chart {
  grid-area: negotiations-premiums;
}

//.under-processing-negotiations-for-lob--chart {
//  grid-area: under-processing-negotiations-for-lob;
//}
//
//.under-processing-premiums-for-lob--chart {
//  grid-area: under-processing-premiums-for-lob;
//}

.submission-reception--chart {
  grid-area: submission-reception;
}

.quoted-premiums-on-received--chart {
  grid-area: quoted-premiums-on-received;
}

.bound-premiums-on-total--chart {
  grid-area: bound-premiums-on-total;
}

.negotiations-results--chart {
  grid-area: negotiations-results;
}

.monthly-hit-ratio--chart {
  //grid-area: monthly-hit-ratio;
}

.annual-hit-ratio--chart {
  //grid-area: annual-hit-ratio;
}

.net-premium-our-share--chart {
  grid-area: net-premium-our-share;
}

.net-premium-our-share-type-business--chart {
  grid-area: net-premium-our-share-type-business;
}

.broker-data-totals--chart {
  grid-area: broker-data-totals;
}

.broker-data--table {
  grid-area: broker-data--table;
  transition: height ease-in 1s;
}

.annual-goal--chart {
  grid-area: annual-goal;
}

.trend-agency--chart {
  grid-area: trend-agency;
}

.under-processing-agency-negotiations-status--chart {
  grid-area: under-processing-agency-negotiations-status;
}

.gap-to-pipeline--chart {
  grid-area: gap-to-pipeline;
}

.net-premium-our-share-sum--chart {
  grid-area: net-premium-our-share-sum;
}

.net-premium-our-share-sum--table {
  grid-area: net-premium-our-share-sum--table;
  transition: height ease-in 1s;
}

.account-negotiations-count--chart {
  grid-area: account-negotiations-count
}

.negotiation-overview--charts {
  grid-area: negotiation-overview
}

.hit-ratio-container {
  grid-area: hit-ratio-container
}

.hit-ratio-select {
  width: 7.5rem;
  grid-area: hit-ratio-select;
}

@media screen and (min-width: 1400px) {
  .dashboard-uw-container {
    display: grid;
    grid-gap: 1.5rem;
    grid-template:
          //'dashboard-data-header dashboard-data-header dashboard-data-header dashboard-data-header' auto
            'negotiation-overview negotiation-overview negotiation-overview negotiation-overview' auto
          'negotiations-status  negotiations-status negotiations-premiums negotiations-premiums' auto
          //'under-processing-negotiations-for-lob under-processing-negotiations-for-lob under-processing-premiums-for-lob under-processing-premiums-for-lob' auto
          'status-negotiations-header status-negotiations-header status-negotiations-header status-negotiations-header' auto
          'submission-reception submission-reception quoted-premiums-on-received bound-premiums-on-total' auto
          'submission-reception submission-reception negotiations-results negotiations-results' auto
          'hit-ratio-header hit-ratio-header hit-ratio-header hit-ratio-header' auto
          //'monthly-hit-ratio monthly-hit-ratio annual-hit-ratio annual-hit-ratio' auto
            'hit-ratio-select hit-ratio-select hit-ratio-select hit-ratio-select' auto
            'hit-ratio-container hit-ratio-container hit-ratio-container hit-ratio-container' auto
          'net-premium-our-share net-premium-our-share net-premium-our-share net-premium-our-share' auto
            'net-premium-our-share-sum--table net-premium-our-share-sum--table net-premium-our-share-sum--table net-premium-our-share-sum--table' auto
            'net-premium-our-share-type-business net-premium-our-share-type-business net-premium-our-share-type-business net-premium-our-share-type-business' auto
          'broker-data-totals broker-data-totals broker-data-totals broker-data-totals' auto
          'broker-data--table broker-data--table broker-data--table broker-data--table' auto
          'annual-goal annual-goal annual-goal annual-goal' auto
          'trend-agency trend-agency trend-agency trend-agency' auto
          'under-processing-agency-negotiations-status under-processing-agency-negotiations-status under-processing-agency-negotiations-status under-processing-agency-negotiations-status' auto
          'gap-to-pipeline gap-to-pipeline gap-to-pipeline gap-to-pipeline' auto
          'monthly-data-header monthly-data-header monthly-data-header monthly-data-header' auto
          'net-premium-our-share-sum net-premium-our-share-sum account-negotiations-count account-negotiations-count' auto;
    //grid-template-columns: calc((100% - 3rem) / 4) calc((100% - 3rem) / 4) calc((100% - 3rem) / 4) calc((100% - 3rem) / 4);
    grid-template-columns: calc((100% - 3rem) / 4) calc((100% - 6rem) / 4) calc((100% - 6rem) / 4) calc((100% - 3rem) / 4)
  }
}

.broker-portfolio--chart {
  grid-area: broker-portfolio;
}

.lob-portfolio--chart {
  grid-area: lob-portfolio;
}

.belated-history--table {
  grid-area: belated-table;
}

@media screen and (min-width: 1400px) {
  .dashboard-portfolio-container {
    display: grid;
    grid-gap: 1.5rem;
    grid-template:'broker-portfolio broker-portfolio lob-portfolio lob-portfolio' auto
                  'belated-table belated-table belated-table belated-table' auto;
    grid-template-columns: calc((100% - 3rem) / 4) calc((100% - 6rem) / 4) calc((100% - 6rem) / 4) calc((100% - 3rem) / 4)
  }
}

@media screen and (max-width: 1400px) {
  .dashboard-uw-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .dashboard-portfolio-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}

.params {
  grid-area: params
}

.pie-chart {
  grid-area: pie-chart
}

.info {
  grid-area: info
}

@media screen and (max-width: 768px) {
  .pie-chart-container {
    display: grid;
    grid-template:'params'
                  'pie-chart'
                  'info';
    gap: 0.5rem 0;
  }
}

@media screen and (min-width: 768px) {
  .pie-chart-container {
    display: grid;
    grid-template: 'pie-chart params' auto
                   'pie-chart info' 1fr;
    grid-template-columns: 55% 45%;
    grid-auto-rows: minmax(min-content, max-content);
    gap: 0.5rem 0;
  }
}


// HIGHCHARTS
.highcharts-container, .highcharts-container svg {
  width: 100% !important;
}


// SURVEYJS
.question-title {
  font-weight: normal;
}

.checklist-modal .ant-modal-mask {
  z-index: 2000 !important;
}

div.historyModalCheckbox > .ant-checkbox-wrapper {
  width: 100%;
}

.belated-history-table {
  max-height: 400px;
  overflow: scroll;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
}

.belated-history-table td {
  background-color: #fff;
}

.ant-card-head, .highcharts-container {
  margin-top: 1px;
}

@primary-color: #1890FF;@card-radius: 12px;